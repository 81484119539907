<template>
  <div class="mobile-card-detail-bg">
    <div class="mobile-card-detail-content">
      <div style="display: flex">
        <div style="width: 12px;height: 48px;background-color: #FFC569;border-radius: 12px">
        </div>
        <p style="color: #4C4C4C;font-weight: 800;line-height:28px ;font-family: 'PingFang SC';font-size: 22px;flex: 1;padding-left: 20px;box-sizing: border-box">
          {{ decryptTitle }}</p>
      </div>
      <p style="width: 100%;height: 1px;background-color: #999999;margin: 20px 0px;opacity: 0.3"></p>
      <p style="color: #4C4C4C;font-family: 'PingFang SC';font-weight: 400;font-size: 16px;line-height: 24px;flex: 1;overflow-y: auto;">
        {{ decryptContent }}</p>
      <div style="margin: 20px 0px 0px;display: flex;color: #999999;font-size: 10px">
        <div>
          <p>Latest upgrade:</p>
          <p style="margin-top: 5px">{{ showUpdateTime }}</p>
          <div style="margin: 20px 0px 0px;display: flex;flex-wrap: wrap">
            <div v-for="(label,index ) in labels" :key="index" class="label-item">
              <p style="font-size: 5px">{{ label }}</p>
            </div>
          </div>
        </div>
        <div style="width: 50%">
          <p>Created on: {{ showCreateTime }}</p>
          <p style="margin-top: 8px">Reviewed {{ read_number }} time(s)</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getNote} from '../../../api/reff'
import '../../../assets/common/common.css'
import '../../../assets/common/font.css'
import {decryptValue} from "../../../utils/common";

export default {
  name: "Index",
  data() {
    return {
      isInEditMode: false,
      id: '',
      noteInfo: {},
      showUpdateTime: '',
      showCreateTime: '',
      decryptTitle: '',
      decryptContent: '',
      read_number: '',
      labels: []
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.fetchData()
  },
  methods: {

    fetchData() {
      getNote(this.id).then((res) => {
        let result = res.data.data
        this.cardNoteInfo = result
        this.read_number = result.read_number
        let des_content_new = result.des_content_new
        this.decryptContent = this.doDecryptValue(des_content_new)
        let des_title_new = result.des_title_new
        this.decryptTitle = this.doDecryptValue(des_title_new)
        this.showCreateTime = this.formatShowTime(this.cardNoteInfo.create_time)
        this.showUpdateTime = this.formatShowTime(this.cardNoteInfo.update_time)
        this.labels = []
        for (let i = 0; i < result.des_labels_new.length; i++) {
          let label = this.doDecryptValue(result.des_labels_new[i])
          this.labels.push(label)
        }
      })
    },
    formatShowTime(time) {
      let create_time = time
      var date = new Date(create_time * 1000)
      let year = date.getFullYear()
      let day = date.getDate()
      let month = (date.getMonth() + 1)
      return this.formatMonthToEng(month) + ' ' + day + ', ' + year
    },
    doDecryptValue(encrypted) {
      return decryptValue(encrypted)
    },
    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return 'Jan'
        case 2:
          return 'Feb'
        case 3:
          return 'Mar'
        case 4:
          return 'Apr'
        case 5:
          return 'May'
        case 6:
          return 'Jun'
        case 7:
          return 'Jul'
        case 8:
          return 'Aug'
        case 9:
          return 'Sep'
        case 10:
          return 'Oct'
        case 11:
          return 'Nov'
        case 12:
          return 'Dec'
      }
    },
  }
}
</script>

<style scoped lang="less">

.label-item {
  margin-right: 5px;
  padding: 2px 6px;
  color: #1A51CD;
  border-radius: 12px;
  border-style: solid;
  border-color: #1A51CD;
  border-width: 1px;
  margin-bottom: 5px;
}

.mobile-card-detail-content {
  border-radius: 25px;
  padding: 30px 15px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.mobile-card-detail-bg {
  padding: 15px;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: #FFC569;
}

</style>
